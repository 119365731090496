<template>
    <div class="wallet">
        <div class="header">
            <a-icon @click="$router.go(-1)" type="left" />
            <span class="title">{{$t('wallet.title')}}</span>
            <a-icon @click="goWithdrawls" type="file-text" />
        </div>
        <div class=" balance">
            <div class="label">
                <span>{{$t('wallet.amountLabel')}}</span>
            </div>
            <div class="flex">
                <div class="amount">
                    {{(userinfo.balance/1000000).toFixed(2)}} USDT
                </div>
                <div class="btn btn-primary withdrawl-btn" @click="withdrawl">
                    {{$t('wallet.withdrawl.btn')}}
                </div>
            </div>
        </div>
        <div class="lis">
            <div v-for="l,i in list" :key="'log-'+i" class="li-item">
                <div class="time color-gray">
                    {{l.created_at}}
                </div>
                <div class="flex">
                    <div class="label">{{$t('wallet.typeText')[l.type]}}</div>
                    <div class="amount">
                        {{l.amount/1000000}} <span>u</span>
                    </div>
                </div>
            </div>
            <div v-if="showLoadingMore"
                :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
            >
                <a-spin v-if="loading" />
                <a-button v-else type="dashed" ghost @click="getLogs()">
                    {{$t('common.more')}}
                </a-button>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name: "Wallet",
    data() {
        return {
            userinfo: {
                address: '',balance:0.00,u_balance: 0, node_level:0,id:0,total_flow:0,defi_deposit_total:0,defi_deposit_profits_total:0,released_total:0,citizen_std_total:1,defi_lock_total:0,defi_lock_profits_total:0
            },
            showTotalFlow:true,
            noFundPass: false,
            loading: false,
            showLoadingMore:true,
            page:1,
            pageSize:10,
            asc:0,
            list:[],
            usdt_rate:1,
            energyLeft: 0,
        };
    },
    components: {},
    mounted() {
        this.getUserinfo();
        this.getLogs();
    },
    methods: {
        getUserinfo() {
            let _this = this
            this.$http.call(this.$http.api.userinfo).then(res=>{
                console.log(res);
                _this.userinfo = res.data.data;
                _this.$store.commit('setUserinfo', res.data.data);

            },res=>{
                // console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        getData(append = true){
            this.loading = true;
            this.$http.call(this.$http.api.wallets,{
                data:{page:this.page,size:this.pageSize,sort:this.asc}
            }).then(res=>{
                // console.log(res);
                this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    // this.page++;
                    if(append){
                        this.list = this.list.concat(resp.data);
                    }else{
                        this.list = resp.data;
                    }
                    // if(resp.data.length<this.pageSize){
                    //     this.showLoadingMore = false;
                    // }
                }else{
                    this.$message.error(resp.data);
                } 
                
            },res=>{
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        getLogs(append = true){
            this.loading = true;
            this.$http.call(this.$http.api.ulogs,{
                data:{page:this.page,size:this.pageSize,sort:this.asc}
            }).then(res=>{
                console.log(res);
                this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    this.page++;
                    if(append){
                        this.list = this.list.concat(resp.data);
                    }else{
                        this.list = resp.data;
                    }
                    if(resp.data.length<this.pageSize){
                        this.showLoadingMore = false;
                    }
                }else{
                    this.$message.error(resp.data);
                } 
                
            },res=>{
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        withdrawl(){
            if(!this.userinfo.fund_password){
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowSetFundpassBox',true);
            }else{
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowWithdrawlBox',true);
            }
        },
        deposit(){
            if(!this.userinfo.fund_password){
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowSetFundpassBox',true);
            }else{
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowDepositSwapBox',true);
            }
        },
        goWithdrawls(){
            this.$router.push({name:'withdrawls'});
        },
        goReleaseLogs(){
            this.$router.push({name:'releaseLogs'});
        },
        goDeposit(){
            this.$router.push({name:'deposit'});
        },
        goDefiLock(){
            this.$router.push({name:'defiLock'});
        },
        goProfit(){
            this.$router.push({name: 'depositProfits'});
        },
        goDefiLockProfits(){
            this.$router.push({name: 'defiLockProfits'});
        }
    },
};
</script>
<style lang="less" scoped>
.wallet {
    width:100%;
    padding: 70px 15px;
    .header{
        padding: 0 15px;
    }
    .balance{
        background: @bg-second;
        padding: 10px;
        border-radius: 10px 10px 0 0;
        // margin-bottom: 15px;
        text-align: left;
        .label{
            color: @primary-color;
            font-weight: 500;
        }
        .withdrawl-btn{
            padding: 5px;
        }
        .amount{
            color: @primary-color;
            font-size: 18px;
        }
    }
    .lis {
        background: @bg-second;
        border-radius: 0 0 15px 15px;
        padding-bottom: 75px;
    }
    .li-item{
        // background: @bg-second;
        padding: 7px 15px;
        text-align: left;
        border-top: 1px solid @black;
        color: @primary-color;
        .time {
            color: #aaa;
        }
    }
}
</style>
